class WasService {
  public static REGISTRATION = {
    name: 'wasService',
    altName: 'WasService',
    create: ({ configuration = {} }) => {
      return new WasService();
    },
  };

  serviceImplementation = {
    _publishAccessToken: () => console.warn('_publishAccessToken() NOT IMPLEMENTED'),
    _moveToLogin: () => console.warn('_lmoveToLogin() NOT IMPLEMENTED'),
  };

  public publishAccessToken() {
    return this.serviceImplementation._publishAccessToken();
  }

  public moveToLogin() {
    return this.serviceImplementation._moveToLogin();
  }

  public setServiceImplementation({
    publishAccessToken: publishAccessTokenImplementation,
    moveToLogin: moveToLoginImplementation,
  }) {
    if (publishAccessTokenImplementation) {
      this.serviceImplementation._publishAccessToken = publishAccessTokenImplementation;
    }
    if (moveToLoginImplementation) {
      this.serviceImplementation._moveToLogin = moveToLoginImplementation;
    }
  }
}

export default WasService;
