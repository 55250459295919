import React, { createContext, useReducer, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

const wasContext = createContext(null);

export function WasProvider({ children, service, wasRoot }) {
  const navigate = useNavigate();

  const publishAccessToken = useCallback(async () => {
    const response = await fetch(wasRoot + '/refresh', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    if (response.status == 200) {
      window.sessionStorage.setItem('accessToken', (await response.json()).accessToken);
      return true;
    }
    else {
      return false;
    }
  }, [wasRoot])

  const moveToLogin = useCallback(() => navigate('/login'), []);

  const api = {
    publishAccessToken,
    moveToLogin
  };

  if (service) {
    service.setServiceImplementation({
      publishAccessToken,
      moveToLogin,
    });
  }

  return (
    <wasContext.Provider value={{ api: api, wasRoot: wasRoot }}>
      {children}
    </wasContext.Provider>
  );
}

WasProvider.propTypes = {
  wasRoot: PropTypes.string.isRequired,
};

WasProvider.defaultProps = {
  wasRoot: '',
};

export const useWAS = () => useContext(wasContext);
