// import React from 'react';
// import { useUserAuthentication } from '@ohif/ui';

// export const PrivateRoute = ({ children, handleUnauthenticated }) => {
//   const [{ user, enabled }] = useUserAuthentication();

//   if (enabled && !user) {
//     return handleUnauthenticated();
//   }

//   return children;
// };

// export default PrivateRoute;

import React from 'react';
import { UserAuthenticationService } from '@ohif/core';
import { useUserAuthentication, useWAS } from '@ohif/ui';
import { Navigate } from "react-router-dom";

export const PrivateRoute = ({ children, authenticationService }) => {
  if (authenticationService instanceof UserAuthenticationService) {
    const [{ user, enabled }] = useUserAuthentication();

    if (enabled && !user) {
      return authenticationService.handleUnauthenticated();
    }
  }
  else if (authenticationService == null) {
    const { api } = useWAS();
    // if (api.getToken() == '')
    if (window.sessionStorage.getItem('accessToken') == null)
      return <Navigate to={"/login"} replace />;
  }

  return children;
};

export default PrivateRoute;
